import styled from 'styled-components'

export const Container = styled.div`
 max-width: 1600px;
 margin: 2rem 0 2rem 15rem;
 padding: 1.5rem;

 background: var(--white);
 border-radius: 0.25rem;
`

export const Title = styled.h2`
 font-size: 0.9rem;
 color: var(--green);
 font-weight: 400;
 text-transform: uppercase;
 line-height: 1.4rem;
 padding: 0 0 1.5rem 0;
`

export const FilterContainer = styled.div`
 display: flex;
 justify-content: space-between;
`

export const FilterContainerSelect = styled.select`
 height: 1.4rem;
 border: 1px solid var(--green);
 font-size: 0.9rem;
 color: var(--green);
 font-weight: 400;
 padding: 0;
`

export const Content = styled.div`
 padding: 1.5rem;

 display: grid;
 grid-template-columns: repeat(4, 1fr);
 gap: 20px;
 align-items: center;
 justify-items: center;

 div {
  text-align: center;
  border-right: 1px solid rgba(112, 112, 112, 0.1);
  width: 100%;

  &:last-of-type {
   border: 0;
  }

  h3 {
   color: var(--black);
   font-size: 0.7rem;
   font-weight: 600;
   padding-top: 0.5rem;
  }

  p {
   color: var(--black);
   font-size: 0.7rem;
   font-weight: 400;
  }
 }
`

export const Table = styled.table`
 width: 100%;
 border-spacing: 0rem;
 border: 1px solid #d8d8d8;

 thead {
  tr {
   background: #c1c1c1;
  }
 }

 th {
  color: var(--white);
  font-size: 0.6rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem;
 }

 tr {
  color: var(--black);
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  border-right: 1px solid #d8d8d8;

  &:nth-child(even) {
   background: #f5f5f5;
  }

  td {
   padding: 0.8rem;
   border-right: 1px solid #d8d8d8;

   &:last-of-type {
    border: 0;
   }

   div {
    border: 1px solid #d8d8d8;
    background: var(--white);
    height: 4rem;
    padding: 4rem;
    position: relative;

    img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
    }
   }

   .lojas-logo img {
    width: 100%;
    display: block;
    margin: 0 auto;
   }

   img {
    width: 1.9rem;
    height: 1.9rem;
    cursor: pointer;
   }
  }
 }
`
