import { useEffect, useMemo, useCallback } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactPaginate from 'react-paginate'
import { useMain } from '../../contexts/MainContext'

import api from '../../services/api'

import { Header } from '../../components/Header'
import { ModalAdd } from '../../components/ModalAdd'
import { ModalEdit } from '../../components/ModalEdit'
import { ModalDelete } from '../../components/ModalDelete'
import { Botao } from '../../components/Botao'

import Edit from '../../assets/edit.svg'
import Checked from '../../assets/checked.svg'
import Unchecked from '../../assets/unchecked.svg'
import Trash from '../../assets/trash.svg'

import {
 Container,
 Title,
 Table,
 FilterContainer,
 FilterContainerSelect
} from './styles'

export function ListaEventos() {
 const title = 'Acontece no Parque'
 const subtitle = 'Lista de Eventos'

 const fields = useMemo(
  () => [
   {
    id: 'Lista de Eventos',
    b_id: String,
    page: String,
    text: String,
    galleryTitle: String,
    gallery: Array,
    show_gallery: Number,
    videosTitle: String,
    videos: String,
    status: Number
   }
  ],
  []
 )

 const {
  contentEventos,
  setContentEventos,
  orderBy,
  setOrderBy,
  modalAdd,
  toggleModalAdd,
  modalEdit,
  toggleModalEdit,
  modalDelete,
  toggleModalDelete,
  id,
  setID,
  offset,
  limit,
  handlePageClick
 } = useMain()

 const pageCount = Math.ceil(contentEventos.length / limit)

 const handleOrderChange = event => {
  setOrderBy(event.target.value)
 }

 const loadEventos = useCallback(async () => {
  try {
   const response = await api.get(`acontece?order=${orderBy}`)

   setContentEventos(response.data)
  } catch (error) {
   console.log('Ocorreu um erro ao buscar os conteúdos')
  }
 }, [orderBy, setContentEventos])

 useEffect(() => {
  loadEventos()
 }, [loadEventos])

 return (
  <>
   <ToastContainer />

   {modalAdd && (
    <ModalAdd
     isClose={toggleModalAdd}
     isLoad={loadEventos}
     fields={fields}
     name="acontece"
     title="Conteúdo"
    />
   )}
   {modalDelete && (
    <ModalDelete
     isClose={toggleModalDelete}
     isLoad={loadEventos}
     id={id}
     name="acontece"
     title="Conteúdo"
    />
   )}
   {modalEdit && (
    <ModalEdit
     isClose={toggleModalEdit}
     isLoad={loadEventos}
     id={id}
     name="acontece"
     title="Conteúdo"
     folder="acontecenoparque"
    />
   )}

   <Header title={title} subtitle={subtitle} />
   <Container>
    <FilterContainer>
     <Title>Gerenciador de conteúdo</Title>
     <FilterContainerSelect
      name="filter"
      id="filter"
      onChange={handleOrderChange}
     >
      <option value="mais_recentes">Mais Recentes</option>
      <option value="mais_antigos">Mais Antigos</option>
      <option value="asc">Ordem alfabética (A a Z)</option>
      <option value="desc">Ordem alfabética reversa (Z a A)</option>
      <option value="update_desc">Atualizações mais recentes</option>
      <option value="update_asc">Atualizações mais antigas</option>
     </FilterContainerSelect>
    </FilterContainer>
    {contentEventos.length <= 0 ? (
     <>Nenhum conteúdo cadastrado.</>
    ) : (
     <>
      <Table>
       <thead>
        <tr>
         <th>Exibição</th>
         <th>Página</th>
         <th>Status</th>
         <th colSpan="2"></th>
        </tr>
       </thead>
       <tbody>
        {contentEventos &&
         contentEventos.slice(offset, offset + limit).map(content => (
          <tr key={content.id}>
           <td>{content.id}</td>
           <td>{content.page}</td>
           <td>
            {content.status === 1 ? (
             <img src={Checked} alt="Ativo" />
            ) : (
             <img src={Unchecked} alt="Inativo" />
            )}
           </td>
           <td onMouseDown={() => setID(content.id)}>
            <img
             src={Edit}
             alt="Editar"
             title="Editar"
             onClick={toggleModalEdit}
            />
           </td>
           <td onMouseDown={() => setID(content.id)}>
            <img
             src={Trash}
             alt="Deletar"
             title="Deletar"
             onClick={toggleModalDelete}
            />
           </td>
          </tr>
         ))}
       </tbody>
      </Table>

      <ReactPaginate
       previousLabel={'<'}
       nextLabel={'>'}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={'pagination'}
       previousLinkClassName={'pagination__link'}
       nextLinkClassName={'pagination__link'}
       disabledClassName={'pagination__link--disabled'}
       activeClassName={'pagination__link--active'}
      />
     </>
    )}
   </Container>

   <Botao isOpen={toggleModalAdd} />
  </>
 )
}
